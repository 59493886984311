import React from 'react';
import { SectionHeader } from '../../../modules/common/components/section-header';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';

import '../../generic-page.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { Row } from 'react-bootstrap';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';

const AshGuides: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page ash'} game="ash">
      <ul className="breadcrumb">
        <li>
          <Link to="/ash-echoes/">Ash Echoes</Link>
        </li>
        <li className="divider">/</li>
        <li>Guides</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/ash/categories/category_guides.webp"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Ash Echoes Guides</h1>
          <h2>
            Best guides for Ash Echoes that will help you understand the game
            better
          </h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
      <SectionHeader title="Should You Pull?" />
      <Row
        xs={1}
        md={2}
        lg={2}
        xxl={3}
        className="g-3 category-cards-container"
      >
        <CategoryCard
          title="Should You Pull Leilani"
          link="/ash-echoes/guides/should-you-pull-leilani"
          image={
            <StaticImage
              src="../../../images/ash/categories/category_lel.webp"
              alt="Should You Pull Leilani?"
            />
          }
          isNew
        />
      </Row>
      <SectionHeader title="Newbie Zone" />
      <Row
        xs={1}
        md={2}
        lg={2}
        xxl={3}
        className="g-3 category-cards-container"
      >
        <CategoryCard
          title="Introduction to the game"
          link="/ash-echoes/guides/introduction-to-the-game"
          image={
            <StaticImage
              src="../../../images/ash/categories/category_intro.webp"
              alt="Introduction to the game"
            />
          }
        />
        <CategoryCard
          title="Beginner Guide"
          link="/ash-echoes/guides/beginner-guide"
          image={
            <StaticImage
              src="../../../images/ash/categories/category_beginner.webp"
              alt="Beginner Guide"
            />
          }
        />
        <CategoryCard
          title="Reroll Guide"
          link="/ash-echoes/guides/reroll"
          image={
            <StaticImage
              src="../../../images/ash/categories/category_reroll.webp"
              alt="Reroll Guide"
            />
          }
        />
        <CategoryCard
          title="Tier List"
          link="/ash-echoes/tier-list"
          image={
            <StaticImage
              src="../../../images/ash/categories/category_tier.webp"
              alt="Tier List"
            />
          }
        />
      </Row>
      <SectionHeader title="Generic Guides" />
      <Row
        xs={1}
        md={2}
        lg={2}
        xxl={3}
        className="g-3 category-cards-container"
      >
        <CategoryCard
          title="Elements & Reactions"
          link="/ash-echoes/guides/elements-and-reactions"
          image={
            <StaticImage
              src="../../../images/ash/categories/category_elements.webp"
              alt="Elements & Reactions"
            />
          }
        />
        <CategoryCard
          title="Game Modes"
          link="/ash-echoes/guides/game-modes"
          image={
            <StaticImage
              src="../../../images/ash/categories/category_modes.webp"
              alt="Game Modes"
            />
          }
        />
        <CategoryCard
          title="Echoing Nexus Overview"
          link="/ash-echoes/guides/echoing-nexus"
          image={
            <StaticImage
              src="../../../images/ash/categories/category_nexus.webp"
              alt="Echoing Nexus Overview"
            />
          }
        />
        <CategoryCard
          title="Inheritance Guide"
          link="/ash-echoes/guides/inheritance-guide"
          image={
            <StaticImage
              src="../../../images/ash/categories/category_inherit.webp"
              alt="Inheritance Guide"
            />
          }
          isNew
        />
        <CategoryCard
          title="Team Building"
          link="/ash-echoes/guides/team-building"
          image={
            <StaticImage
              src="../../../images/ash/categories/category_teams.webp"
              alt="Team Building"
            />
          }
        />
      </Row>
      <SectionHeader title="Event and Boss Guides" />
      <Row
        xs={1}
        md={2}
        lg={2}
        xxl={3}
        className="g-3 category-cards-container"
      >
        <CategoryCard
          title="Joint Training: Legacy of the Abyss"
          link="https://blog.prydwen.gg/2025/04/03/ash-echoes-joint-training-legacy-of-the-abyss/"
          image={
            <StaticImage
              src="../../../images/ash/categories/category_legacy.webp"
              alt="Joint Training: Legacy of the Abyss"
            />
          }
          outsideLink
          isNew
        />
        <CategoryCard
          title="Simultaneous Raid: Iris"
          link="https://blog.prydwen.gg/2025/04/03/ash-echoes-mercy-upon-us-simultaneous-raid-iris/"
          image={
            <StaticImage
              src="../../../images/ash/categories/category_iris.webp"
              alt="Simultaneous Raid: Iris"
            />
          }
          outsideLink
          isNew
        />
        <CategoryCard
          title="Simultaneous Raid: Shriek Party"
          link="https://blog.prydwen.gg/2025/03/30/ash-echoes-shriek-party-sim-raid/"
          image={
            <StaticImage
              src="../../../images/ash/categories/category_shriek.webp"
              alt="Simultaneous Raid: Shriek Party"
            />
          }
          outsideLink
          isNew
        />
        <CategoryCard
          title="Joint Training: Shriek Party"
          link="/ash-echoes/guides/joint-training-shriek-party"
          image={
            <StaticImage
              src="../../../images/ash/categories/category_party.webp"
              alt="Joint Training: Shriek Party"
            />
          }
        />
        <CategoryCard
          title="Echo Hunt: Dissonance - Du Wang"
          link="/ash-echoes/guides/echo-hunt-du-wang"
          image={
            <StaticImage
              src="../../../images/ash/categories/category_du.webp"
              alt="Echo Hunt: Dissonance - Du Wang"
            />
          }
        />
        <CategoryCard
          title="Joint Training: Supreme Altar"
          link="https://blog.prydwen.gg/2025/02/24/ash-echoes-joint-traing-supreme-alter-guide/"
          image={
            <StaticImage
              src="../../../images/ash/categories/category_jtaltar.webp"
              alt="Joint Training: Supreme Altar"
            />
          }
          outsideLink
        />
      </Row>
      <SectionHeader title="Database" />
      <Row
        xs={1}
        md={2}
        lg={2}
        xxl={3}
        className="g-3 category-cards-container"
      >
        <CategoryCard
          title="Memory Traces"
          link="/ash-echoes/memory-traces"
          image={
            <StaticImage
              src="../../../images/ash/categories/category_traces.webp"
              alt="Memory Traces"
            />
          }
        />
        <CategoryCard
          title="Tea Time Drinks"
          link="/ash-echoes/tea-time"
          image={
            <StaticImage
              src="../../../images/ash/categories/category_tea.webp"
              alt="Tea Time Drinks"
            />
          }
        />
      </Row>
      <br />
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
    </DashboardLayout>
  );
};

export default AshGuides;

export const Head: React.FC = () => (
  <Seo
    title="Guides | Ash Echoes | Prydwen Institute"
    description="Best guides for Ash Echoes that will help you understand the game better."
    game="ash"
  />
);
